// @core/scss/base/bootstrap-extended/_variables.scss
@import "@styles/base/bootstrap-extended/_variables.scss";
$purple: #3e38df !important; //$primary
$red: #f72119 !important; //$danger
$primary: $purple !default;

// @core/scss/base/bootstrap-extended/_dropdown.scss
.dropdown-auto {
  animation-name: auto !important;
}

// @core/scss/base/bootstrap-extended/_reboot.scss
// body {
//   margin: 0; // 1
//   font-family: var(--#{$variable-prefix}body-font-family) !important;
//   @include font-size(var(--#{$variable-prefix}body-font-size));
//   font-weight: var(--#{$variable-prefix}body-font-weight);
//   line-height: var(--#{$variable-prefix}body-line-height);
//   color: var(--#{$variable-prefix}body-color);
//   text-align: var(--#{$variable-prefix}body-text-align);
//   background-color: var(--#{$variable-prefix}body-bg); // 2
//   -webkit-text-size-adjust: 100%; // 3
//   -webkit-tap-highlight-color: rgba($black, 0); // 4
// }

@mixin h1 {
  @extend %heading;
  @include font-size($h1-font-size !important);
}

@mixin h2 {
  @extend %heading;
  @include font-size($h2-font-size !important);
}

@mixin h3 {
  @extend %heading;
  @include font-size($h3-font-size !important);
}

@mixin h4 {
  @extend %heading;
  @include font-size($h4-font-size !important);
}

@mixin h5 {
  @extend %heading;
  @include font-size($h5-font-size !important);
}

@mixin h6 {
  @extend %heading;
  @include font-size($h6-font-size !important);
}

// @core/scss/base/bootstrap-extended/_utilities.scss
@import "@styles/base/core/mixins/main-menu-mixin.scss";
.cursor-default {
  cursor: default !important;
}

// @core/scss/base/bootstrap-extended/_navbar.scss
.header-navbar {
  // static nav
  &.navbar-static-top {
    box-shadow: $box-shadow; // remove this if removing "menu-shadow" class
  }
}

.navbar-static-custom {
  background-color: #fff !important;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}

// @core/scss/base/core/layouts/_content.scss
// @include media-breakpoint-down(md) {
//   html body .app-content {
//     padding: initial !important; // - 0.8rem to reduce padding in small screen
//   }
// }

// @core/scss/base/core/layouts/_footer.scss
.scroll-top {
  bottom: 100px;
}

// @core/scss/base/core/menu/menu-types/horizontal-menu.scss
.horizontal-menu {
  .horizontal-menu-wrapper {
    top: unset !important;
  }

  .header-navbar {
    &.navbar-horizontal.floating-nav {
      top: unset !important;
    }
  }
}

.horizontal-layout {
  &.navbar-sticky {
    .horizontal-menu-wrapper {
      .navbar-horizontal.header-navbar.fixed-top {
        top: unset !important;
      }
    }
  }
}

// @core/scss/base/plugins/extensions/ext-component-tour.scss
.shepherd-element {
  .shepherd-content {
    .shepherd-header {
      background-color: $white;

      .shepherd-title {
        color: unset !important;
      }
      .shepherd-cancel-icon {
        color: unset !important;
      }
    }

    .shepherd-footer {
      padding: 1rem 1.2rem 1rem;
    }
  }
}

// @core/scss/react/app-loader.scss
.fallback-spinner {
  .loading {
    .effect-1,
    .effect-2,
    .effect-3 {
        border-left: 3px solid var(--bs-primary);
    }
  }
}

// input switch
[dir] .form-switch .form-check-input {
  background-image: url('../../../assets/images/freegifts/input-switch.svg') !important;
}