.mb-2r {
  margin-bottom: 2rem;
}

[dir] .form-switch .form-check-input {
  background-image: url("../../assets/images/freegifts/input-switch.svg") !important;
}

.float-right {
  float: right;
}

.tooltip-wrapper {
  transition: 0.15 ease all;
}

.tooltip-inner {
  max-width: 420px !important;
  text-align: left;
}

.flat-picker-graph {
  max-width: 220px;
}

.min-vh-50 {
  min-height: 50vh;
  max-height: 500px;
}

.bg-brand-mark {
  background-color: #d9d9d9 !important;
}

.misc-inner-menu {
  min-height: auto !important;
}

.rdt_TableHeadRow [data-column-id="loading"] {
  display: none;
}

.start-guide .accordion-margin .accordion-item.shadow-none {
  box-shadow: none !important;
}

.start-guide .accordion-margin .accordion-item.is_done .accordion-button .badge {
  background: #3e38dfac !important;
}

.start-guide .accordion-margin .accordion-item.is_done .accordion-button:not(.collapsed) .badge {
  background: #3e38df !important;
}

.start-guide .accordion-button::after {
  background: unset !important;
}

.start-guide .accordion-button:not(.collapsed) {
  color: var(--p-text);
  font-weight: 600;
}

.start-guide .accordion-button:not(.collapsed) .badge {
  background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.start-guide .img-sca {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  width: 325px;
}

.img-sca-shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.sca-start-guide [id*="SvgjsText"] {
  transform: translateY(-12px);
}

.start-guide .accordion-header.not_dashboard .accordion-button {
  padding: 1rem 0 0 0;
}
@media screen and (max-width: 568px) {
  .start-guide .accordion-header.dashboard .accordion-button {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .sca-start-guide-tab {
    top: auto;
    width: auto;
    right: 0;
    left: 0;
    height: 70vh;
    max-height: 100%;
    border-top: 0 solid rgba(34, 41, 47, 0.2);
    transform: translateY(100%);
  }
  .sca-start-guide-tab.show {
    transform: none;
  }
}

.transition-none {
  transition: none !important;
}

.block-status input {
  cursor: pointer;
}

.Type-select {
  width: 100% !important;
}
.User-select .User-select__value-container,
.User-select__multi-value__label {
  color: #fff !important;
}
@import url(./loading.css);
