@-webkit-keyframes loading-skeleton {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading-skeleton {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

div[class*="Polaris-Skeleton"] {
  -webkit-animation: loading-skeleton 1s infinite alternate;
  animation: loading-skeleton 1s infinite alternate;
}

.skeleton-load-center div[class*="Polaris-Skeleton"] {
  margin: auto;
}

.react-dataTable.table-skeleton .rdt_TableCol_Sortable {
  height: auto;
  display: block;
}